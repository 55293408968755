* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }
  
  .hideLang{
    opacity: 0.4;
    transition: 0.5s ease;
  }
  .hideLang:hover{
    opacity: 0.9;
  }

  @media screen and (max-width : 960px) {
    
  .logo_innio{
    margin-right: 50px;
  }
  }