.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width:330px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: rgb(223, 223, 223);
  margin:15px;
  box-shadow: 4px 4px 10px 0px rgb(34 34 34);
  transition: all 1.4s ease-in-out;
  overflow: hidden;
}
.swiper-slide:hover{
  box-shadow: 5px 5px 10px 0px rgb(0 0 0);
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-button-prev{
  left:0;
}
.swiper-button-next{
  right:0;
}